@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NeueHaasDisplay";
  src: url("fonts/NeueHaasDisplay.woff2");
}

@font-face {
  font-family: "Helvetica-Neue-LT-Std-83-Heavy-Extended";
  src: url("fonts/Helvetica-Neue-LT-Std-83-Heavy-Extended.woff2");
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    color: white;
  }

  .svgicon,
  .svgicon path {
    fill: white !important;
  }

  .light .svgicon,
  .light .svgicon path {
    fill: black !important;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: #eaeaea;
    color: black;
  }

  .svgicon,
  .svgicon path {
    fill: black !important;
  }

  .dark .svgicon,
  .dark .svgicon path {
    fill: white !important;
  }
}

::selection {
  background-color: #d73646;
  color: white;
}

.blackSelection *::selection {
  background: black;
  color: white;
}

body {
  font-family: "NeueHaasDisplay";
  overflow-x: hidden;
}

h1 {
  @apply text-3xl;
  @apply xs:text-4xl;
  @apply sm:text-6xl;
  @apply md:text-7xl;
  @apply lg:text-8xl;
  @apply xl:text-9xl;
  font-family: "Helvetica-Neue-LT-Std-83-Heavy-Extended";
}

h2 {
  @apply text-3xl;
  @apply xs:text-4xl;
  @apply sm:text-3xl;
  @apply md:text-4xl;
  @apply lg:text-5xl;
}

h3 {
  @apply text-2xl;
  @apply xs:text-3xl;
  @apply sm:text-2xl;
  @apply md:text-3xl;
  @apply lg:text-4xl;
}

h4 {
  @apply text-lg;
  @apply xs:text-xl;
  @apply sm:text-lg;
  @apply md:text-2xl;
  @apply lg:text-3xl;
}

h5 {
  @apply text-lg;
  @apply xs:text-xl;
  @apply sm:text-lg;
  @apply md:text-xl;
  @apply lg:text-2xl;
}

.menuEntrySeperator {
  margin: 0 0.01em;
}

.menuEntry:hover .menuEntrySeperator {
  margin: 0 0.25em;
}

.svgicon,
.svgicon path {
  transition-duration: 300ms;
}

.svgicon-red,
.svgicon-red path {
  fill: #d73646 !important;
}

.svgicon-white,
.svgicon-white path {
  fill: #eaeaea !important;
}

.svgicon-black,
.svgicon-black path {
  fill: black !important;
}
